import { mapState } from 'vuex'
import { BasicPermissions } from '@/utils/permissions'
import Perfil from '@/utils/perfil'
import { Sequences, CustomPermissions } from '@/utils/permissions'

export const computed = {
  ...mapState('auth', ['user', 'empresaAtual', 'perfilAtual']),
  ...mapState('patio', ['motivoscancelamento']),
  titlePesoExato() {
    let placa = '-'
    let tipo = 'exato'
    if (this.agendamentoPesoExato) {
      if (this.agendamentoPesoExato.veiculo) {
        placa = this.agendamentoPesoExato.veiculo.placa_cavalo
      }
      if (this.agendamentoPesoExato.extra) {
        if (this.agendamentoPesoExato.extra.agendamento) {
          if (this.agendamentoPesoExato.extra.agendamento.peso_exato) {
            tipo = 'normal'
          }
        }
      }
    }
    return `Confirmar agendamento (${placa}) como peso ${tipo}?`
  },
  ehFornecedor() {
    return this.empresaAtual.tiposContrato.includes(Perfil.Fornecedor)
  },

  ehGate() {
    return this.empresaAtual.gate && this.perfilAtual == Perfil.Unidade
  },

  orderedListagem() {
    this.selecionados = []
    return this.data.sort((a, b) =>
      this.getTempo(a.triado_em) > this.getTempo(b.triado_em) ? -1 : 1
    )
  },

  canCancel() {
    return this.$canDo(
      CustomPermissions.CANCELAR,
      Sequences.GerenciamentoServico.toString()
    )
  },

  canCancelService() {
    return this.$canDo(
      CustomPermissions.CANCELAR_SERVICO,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canAdditionalService() {
    return this.$canDo(
      CustomPermissions.SERVICO_ADICIONAL,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canPause() {
    return this.$canDo(
      CustomPermissions.PAUSAR,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canWeigh() {
    return this.$canDo(
      CustomPermissions.PESAR,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canAdvanceSteps() {
    return this.$canDo(
      CustomPermissions.AVANCAR_ETAPAS,
      Sequences.GerenciamentoServico.toString()
    )
  },

  canEntradaBuffer() {
    return this.$canDo(
      CustomPermissions.ENTRADA_BUFFER,
      Sequences.GerenciamentoServico.toString()
    )
  },

  canSaidaBuffer() {
    return this.$canDo(
      CustomPermissions.SAIDA_BUFFER,
      Sequences.GerenciamentoServico.toString()
    )
  },

  canMagnifyingGlass() {
    return this.$canDo(
      CustomPermissions.MAGNIFYING_GLASS,
      Sequences.GerenciamentoServico.toString()
    )
  },

  canBackoffSteps() {
    return this.$canDo(
      CustomPermissions.RECUAR_ETAPAS,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canViewScheduleReceipt() {
    return this.$canDo(
      CustomPermissions.VISUALIZAR_COMPROVANTE_AGENDAMENTO,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canViewSortingReceipt() {
    return this.$canDo(
      CustomPermissions.VISUALIZAR_COMPROVANTE_TRIAGEM,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canViewCleaningReceipt() {
    return this.$canDo(
      CustomPermissions.VISUALIZAR_COMPROVANTE_LIMPEZA,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canSendClassification() {
    return this.$canDo(
      CustomPermissions.ENVIAR_CLASSIFICACAO,
      Sequences.GerenciamentoServico.toString()
    )
  },
  quantidadeSelecionada() {
    if (Array.isArray(this.selecionados) && 'length' in this.selecionados) {
      return this.selecionados.length
    } else {
      return 0
    }
  },
  canEnviarCadastroMotorista() {
    return this.$canDo(
      CustomPermissions.ENVIAR_CADASTRO_MOTORISTA,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canBypassDate() {
    return this.$canDo(
      CustomPermissions.IGNORAR_DATA_TRIAGEM,
      Sequences.GerenciamentoServico.toString()
    )
  },
  isRelatorio() {
    return this.$route.name === 'CargaDescargaRelatorio'
  },

  canList() {
    return this.$canDo(
      BasicPermissions.VISUALIZAR,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canCreate() {
    return this.$canDo(
      BasicPermissions.CADASTRAR,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canUpdate() {
    return this.$canDo(
      BasicPermissions.EDITAR,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canChangeQuota() {
    return this.$canDo(
      CustomPermissions.CHANGE_QUOTA,
      Sequences.GerenciamentoServico.toString()
    )
  },
  canDelete() {
    return this.$canDo(
      BasicPermissions.EXCLUIR,
      Sequences.GerenciamentoServico.toString()
    )
  },
}
