<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
    <v-row id="actions">
      <v-col class="d-flex pa-8">
        <v-btn
          type="button"
          color="primary"
          @click="fecharComprovanteAgendamento"
          >Fechar</v-btn
        >
        <v-spacer />
        <v-btn
          color="success"
          v-if="
            ehGate &&
            showShortcurt &&
            agendamento.gate.public_id == empresaAtual.public_id
          "
          @click="handleGerarTriagem(agendamento)"
          >Gerar Triagem</v-btn
        >
        <v-spacer />
        <v-btn color="secondary" @click="imprimirComprovante">Imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-space-between" id="logos">
      <v-col cols="4">
        <v-img
          class="mx-auto"
          contain
          height="100"
          width="150"
          src="@/assets/images/icon/logo.png"
        />
      </v-col>
      <v-col cols="4">
        <v-img
          class="mx-auto"
          contain
          height="100"
          width="150"
          src="@/assets/images/icon/logo.png"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h1>Comprovante de Agendamento</h1>
      </v-col>
    </v-row>
    <v-row :class="class_agendamento">
      <v-col>
        <h2>Janela para marcação na Triagem</h2>
      </v-col>
    </v-row>
    <v-row :class="class_agendamento">
      <v-col>
        <p>
          <span>
            Data do agendamento:
            {{ agendamento.agendado_em | toBrazilianDateTime }}
          </span>
        </p>
        <p>
          <span>
            Agendado por:
            <strong>{{ agendamento.agendado_por }}</strong>
          </span>
        </p>

        <p v-if="agendamento.data_cota">
          <span>
            Data da cota:
            <strong v-if="cota_dia_todo(agendamento.data_cota)">{{
              agendamento.data_cota.data_inicio | iso2br
            }}</strong>
            <strong v-else-if="cota_mesmo_dia(agendamento.data_cota)">
              {{ mostra_cota_mesmo_dia(agendamento.data_cota) }}</strong
            >
            <strong v-else>
              {{ agendamento.data_cota.data_inicio | toBrazilianDateTime }}
              -
              {{ agendamento.data_cota.data_fim | toBrazilianDateTime }}
            </strong>
          </span>
        </p>
        <p v-if="agendamento.data_janela">
          <span>
            Data da janela:
            <strong>
              {{
                agendamento.data_janela.data_inicio | toBrazilianDateTimeAdded
              }}
              -
              {{ agendamento.data_janela.data_fim | toBrazilianDateTimeAdded }}
            </strong>
          </span>
        </p>
        <p v-if="agendamento.data_pedido">
          <span>
            Data da pedido:
            <strong>{{ agendamento.data_pedido | date2br }}</strong>
          </span>
        </p>
        <p class="mt-5" v-if="agendamento.cancelado_em">
          <span
            >Data do cancelamento:
            {{ agendamento.cancelado_em | toBrazilianDateTime }}</span
          >
        </p>
        <p v-if="agendamento.cancelado_por">
          <span>Cancelamento por: {{ agendamento.cancelado_por }}</span>
        </p>
      </v-col>
    </v-row>
    <v-row
      v-if="
        agendamento.gate.public_id == '7b551abe-0e44-4470-8ca6-182526c59d57'
      "
    >
      <v-col class="col-comprovante">
        <p>
          <strong>Proibido a descarga de caminhões 25 metros graneleiro</strong>
        </p>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-row>
        <v-col>
          <h3 class="mb-4">Informações de Carregamento</h3>
          <p>
            <span class="label">Nota Fiscal</span>
            <strong
              v-for="(item, index) in agendamento.pedidos_agendados"
              :key="index"
            >
              {{
                (item.numero_serie || '-') +
                '/' +
                (item.numero_nfe || '-') +
                '/' +
                (item.chave_acesso || '-') +
                ','
              }}
            </strong>
          </p>
          <p>
            <span class="label">Produto</span>
            <strong
              v-for="(item, index) in agendamento.pedidos_agendados"
              :key="index"
            >
              {{ item.pedido.produto.descricao + ',' }}
            </strong>
          </p>
          <p>
            <span class="label">Remetente</span>
            <strong>{{ agendamento.remetente.trade_name }}</strong>
          </p>
          <p>
            <span class="label">Destinatario</span>
            <strong>{{ agendamento.destinatario.trade_name }}</strong>
          </p>
          <p>
            <span class="label">Terminal (Carga/Descarga)</span>
            <strong>{{
              agendamento.terminal.contratado
                ? agendamento.terminal.contratado.trade_name
                : agendamento.terminal.trade_name
            }}</strong>
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="mb-4">Dados do Veículo</h3>
          <p>
            <span class="label">Cavalo</span>
            <strong>{{
              agendamento.veiculo.placa_cavalo || defaultValue
            }}</strong>
          </p>
          <p>
            <span class="label">Modalidade</span>
            <strong>{{
              agendamento.veiculo.modalidade.descricao || defaultValue
            }}</strong>
          </p>
          <p>
            <span class="label">Carroceria</span>
            <strong>{{
              agendamento.veiculo.carroceria.descricao || defaultValue
            }}</strong>
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h3 class="mb-4">Dados do Motorista</h3>
          <p>
            <span class="label">Motorista</span>
            <strong>{{
              agendamento.motorista.user.name || defaultValue
            }}</strong>
          </p>
          <p>
            <span class="label">Cpf</span>
            <strong>{{
              agendamento.motorista.user.cpf || defaultValue
            }}</strong>
          </p>
          <p>
            <span class="label">Celular</span>
            <strong>{{
              agendamento.motorista.user.celular || defaultValue
            }}</strong>
          </p>
        </v-col>
      </v-row>
    </div>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col>
        <h2>Serviços autorizados</h2>
      </v-col>
    </v-row>
    <v-row>
      <table style="padding: 15px">
        <tr>
          <th>Autorizado por</th>
          <th>Serviço autorizado</th>
        </tr>
        <tr
          v-for="(item, index) in agendamento.servicos_agendados"
          :key="index"
        >
          <td>{{ agendamento.agendado_por }}</td>
          <td>{{ item.descricao }}</td>
        </tr>
      </table>
    </v-row>
    <br />
    <br />
    <v-row>
      <v-col>
        <h2>TERMOS DE SERVIÇO</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <strong
          >Equipamento de segurança obrigatório: máscara, sapato fechado, calça,
          óculos, capacete e colete refletivo.</strong
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ul class="text-start">
          <li>
            Agendar apenas caminhões que constam na lista de recebíveis da
            unidade;
          </li>
          <li>
            Não serão aceitos caminhões com excesso de peso ou fora das
            especificações aceitas pela unidade de destino;
          </li>
          <li>
            Assegurar e garantir no ato do carregamento que a carroceria esteja
            limpa e higienizada, sem vestígios de carga anterior, sem risco de
            contaminantes e a lona em adequadas condições;
          </li>
          <li>
            Não deverão ser expedidas cargas em atraso, sem o prévio e expresso
            reagendamento de data e horário;
          </li>
          <li>
            Declara o transportador que não utiliza mão-de-obra escrava ou
            infantil;
          </li>
          <li>
            Anexar na via física da Nota Fiscal o comprovante de agendamento.
          </li>
          <li>
            {{ agendamento.gate.public_id }}
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toReal, date2br } from '@/mixins/convertion.js'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'

import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'

export default {
  name: 'Comprovante',
  inject: {
    fecharComprovanteAgendamento: {
      from: 'fecharComprovanteAgendamento',
    },
    showShortcurt: {
      from: 'showShortcurt',
      default: false,
    },
  },
  mixins: [
    toReal,
    date2br,
    cota_dia_todo,
    cota_mesmo_dia,
    mostra_cota_mesmo_dia,
  ],
  computed,
  filters,
  methods,
}
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

strong::before {
  content: ' ';
}

.label::after {
  content: ':';
}

@media print {
  .v-dialog__content.v-dialog__content--active {
    position: inherit;
  }

  .v-dialog--fullscreen {
    position: absolute;
    overflow: visible;
  }

  #actions {
    display: none;
    height: 0;
  }

  #logos {
    display: none;
  }

  @page {
    margin: 0;
  }
}
</style>
